const colors = {
  primary: '#ffcc00',
  primarylight: '#ffd633',
  primarydark: '#f2c200',
  secondary: '#15151b',
  secondarylight: '#2e2e33',
  secondarydark: '#0b0b0d',
  success: '#0dc884',
  successlight: '#34d198',
  successdark: '#0cba7b',
  warning: '#ff8e00',
  warninglight: '#ffa533',
  warningdark: '#ed8400',
  danger: '#ff4a46',
  dangerlight: '#ff5d59',
  dangerdark: '#e64845',
  info: '#00BCD4',
  infolight: '#00D3EE',
  infodark: '#00A5BB',
  white: '#FFFFFF',
  black: '#000000',
  bodybg: '#ebedf2',
  rowlight: 'F9F9F9',
  rowmiddle: '#F6F6F6',
  rowdark: '#E1E1E1',
  menubg: '#2e2e33',
  menubglight: '#2C3237',
  menubgdark: '#16181B',
  transparent: 'transparent',
  grey: '#797c80',
  greylight: '#9da4ab',
  greylighter: '#a6a6a6',
  greyLightest: '#efefef',
  greydark: '#484848',
  greydarker: '#4d5b69',
  linelight: '#e0e0e0',
  linedark: '#dae1e6',
};

export default colors;
